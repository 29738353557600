<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Netze</h1>
          <p>
            <router-link to="/admin/networks/new" class="button button-ci button-round">
              <img src="../../../assets/icons/plus.svg" />
              <span>Netz hinzufügen</span>
            </router-link>
          </p>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="form-wrap" style="margin-bottom: 20px">
            <input
              v-model="search_phrase"
              v-on:input="search_handler"
              class="form-input"
              type="text"
              name="search_phrase"
              id="search_phrase"
              placeholder="Suchbegriff eingeben..."
            />
          </div>

          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <table class="table">
              <thead>
                <tr>
                  <th>Titel</th>
                  <th>Beschreibung</th>
                  <th>Tags</th>
                  <th>Bildschirme</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="network in networks" v-bind:key="network.uuid">
                  <td width="25%">
                    <router-link :to="'/admin/networks/' + network.uuid" style="color: #444; font-weight: 600">{{ network.title }}</router-link>
                  </td>
                  <td width="25%">
                    {{ network.description }}
                  </td>
                  <td width="20%"><span v-for="search_tag in network.search_tags" v-bind:key="search_tag.uuid" style="margin-right:8px">{{ search_tag.name }}</span></td>
                  <td width="20%"><span v-if="network.network_devices">{{ network.network_devices.length }}</span></td>
                  <td width="10%" style="text-align: right">
                    <router-link :to="'/admin/networks/' + network.uuid" class="campaign-wrap">
                      <span class="material-icons" style="color: #444">create</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row row-gutter-20" style="margin-top: 20px">
              <div class="col-12">
                <a v-if="pagination.links.prev" @click="prev_page" class="button button-ci button-100 button-round button-small">Vorherige Seite</a>
                <a v-else>-</a>
              </div>
              <div class="col-12" style="text-align: right">
                <a v-if="pagination.links.next" @click="next_page" class="button button-ci button-100 button-round button-small">Nächste Seite</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "networks",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      networks: [],
      pagination: {},
      loading: true,
    };
  },
  methods: {
    get_networks(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/networks?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.networks = response.data.networks;
          this.pagination = response.data.meta.pagination;
          this.loading = false;
        });
    },
    search_handler() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/networks?search_phrase=" + this.search_phrase, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.networks = response.data.networks;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    next_page() {
      this.loading = true;
      this.get_networks(this.pagination.links.next.split("=")[1]);
    },
    prev_page() {
      this.loading = true;
      this.get_networks(this.pagination.links.prev.split("=")[1]);
    },
  },
  mounted() {
    this.get_networks(1);
  },
};
</script>

<style lang="scss">
.pending-wrap {
  border: 1px solid #444;
  margin-bottom: 20px;

  h3 {
    width: 100%;
    padding: 14px;
    margin: 0;
    background: #444;
    color: #fff;
  }
}

.media-element {
  max-width: 100%;
  max-height: 140px;
  display: block;
}
</style>
